import React from 'react';

import InputMask from "react-input-mask"
import styles from "./ContactUsModal.module.scss"
import LogoImage from "../../../assets/images/logos/logo.svg"

interface ModalProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const ContactUsModal = ({active, setActive}: ModalProps) => {
  const [formData, setFormData] = React.useState({
    name: "",
    phoneNumber: "",
    companyName: "",
    message: "",
    checked: false,
  })
  const [Error, setError] = React.useState({
    error: false,
    errorMessage: "",
  });
  const [success, setSuccess] = React.useState(false)

  function isValidData(data: any) {
    const invalidPhone = {
      error: true,
      errorMessage: "Введите корректный номер телефона",
    }

    const EmptyName = {
      error: true,
      errorMessage: "Введите корректное имя",
    }

    const Agreement = {
      error: true,
      errorMessage: "Дайте согласие на обработку персональных данных",
    }

    if (formData.name.length <= 1) {
      return {valid: false, error: EmptyName}
    }

    if (formData.phoneNumber.length === 0) {
      return {valid: false, error: invalidPhone}
    }

    if (!formData.checked) {
      return {valid: false, error: Agreement}
    }

    let joinedPhone = formData.phoneNumber.split("");
    for (let i = 0; i < joinedPhone.length; i++) {
      if (joinedPhone[i] === "_") {
        return {valid: false, error: invalidPhone};
      }
    }

    return {
      valid: true, error: {
        error: false,
        errorMessage: "",
      }
    }
  }

  async function SendFormData(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation()
    setError({error: false, errorMessage: ""});
    setSuccess(false)

    const isValid = isValidData(formData)

    if (!isValid.valid) {
      setError(isValid.error)
      return
    }

    await fetch("https://saby.pro/mailsender/api/send-mail", {
      method: "POST",
      body: JSON.stringify({
        userName: formData.name,
        phoneNumber: formData.phoneNumber,
        companyName: formData.companyName,
        userMessage: formData.message,
      }),
      headers: {
        "Content-type" : "application/json; charset=UTF-8",
        "Access-Control-Allow-Headers":"*"
      }
    })
      .then((response) => {
        // @ts-ignore
        if (response.status !== 200) {
          setError({
            error: true,
            errorMessage: "Ошибка! Попробуйте позднее"
          })

          return;
        }
      })
    setSuccess(true)
  }

  return (
    <>
      <div className={active ? styles.modal + " " + styles.active : styles.modal} onClick={() => setActive(false)}>
        <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
          <div className={styles.logoBox}>
            <img src={LogoImage} alt="ЦЕНТР ВНЕДРЕНИЯ | СБИС" className={styles.logo}/>
          </div>

          <h2 className={styles.title}>Оставить заявку</h2>

          <form action="">
            <div className={styles.inputs}>
              <input type="text" placeholder={"Имя *"} className={styles.input} id={"name"} value={formData.name}
                     onChange={e => setFormData({...formData, name: e.target.value})}/>

              <InputMask type="text" placeholder={"Телефон *"} className={styles.input} id={"phone-number"}
                         value={formData.phoneNumber}
                         onChange={e => setFormData({...formData, phoneNumber: e.target.value})}
                         mask={'+7 (999) 999-99-99'}
                         maskChar={"_"}
              />

              <input type="text" placeholder={"Название компании"} className={styles.input} id={"company-name"}
                     value={formData.companyName}
                     onChange={e => setFormData({...formData, companyName: e.target.value})}/>

              <input type="text" placeholder={"Сообщение"} className={styles.input} id={"message"}
                     value={formData.message} onChange={e => setFormData({...formData, message: e.target.value})}/>
            </div>

            <div className={styles.personalDataAgree}>
              <input type="checkbox" id={"personalDataAgreementCh"} name={"personalDataAgreementCh"}
                     value={formData.checked ? "on" : "off"}
                     onChange={e => setFormData({...formData, checked: e.target.checked})}
                     className={styles.input}/>

              <p className={styles.text}>Я соглашаюсь на <span
                onClick={() => window.location.href = "https://online.sbis.ru/shared/disk/7bb47deb-7cc4-4394-bb87-215b510c9c01"}
                className={styles.agreementLink}>обработку персональных данных</span>
              </p>
            </div>

            {Error.error &&
              <p className={styles.errorMessage}>
                {Error.errorMessage}
              </p>
            }

            {success &&
              <p className={styles.successMessage}>
                Ваша заявка принята, в ближайшее время с вами свяжется специалист
              </p>
            }

            <div className={styles.btnBox}>
              <button type={"submit"} className={styles.btn} onClick={e => SendFormData(e)}>Отправить</button>
            </div>
          </form>

        </div>
      </div>
    </>

  );
};

export default ContactUsModal;